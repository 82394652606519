$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.all {
  height: 100vh;
}

.section{
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .modalContainer {
    background-color: rgba(0, 0, 0, 0.02);
    
    .modal {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    
    .modalContent {
      position: absolute;
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: #fff;
      padding: 20px;
      min-width: 300px;
      max-width: 90%;
      min-height: 100px;
      border-radius: 8px;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
      position: relative;
    }
    
    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .messageBox {
    display: flex;
    flex-direction: column;
    border-color: rgb(242, 242, 242);
    border-style: solid;
    border-radius: 20px;
    overflow: auto;
    margin-top: 40px;
    width: 90%;
    height: auto;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: $main-color;
    // background-color: rgb(232, 231, 231);

    .loadingAnimation {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.6);
      width: inherit;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
    }

    @media (max-width: 733px) {
      width: 90%;
    }

    h3 {
      text-align: center;
      color: $secondary-color;
      font-size: 25px;
      margin-bottom: 1rem;
    }

    p {
      text-align: center;
      color:$tertiary-color;
      margin: 5px;
    }
  
    .messageBoxContent {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: flex-start;
      height: auto;

      .textMessageBox {
        margin-top: 10px;
        width: 100%;
        justify-content: center;
        display: flex;

        .textMessage {
          text-align: left;
          border-color: rgb(198, 198, 198);
          border-radius: 7px;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
          resize: none;
          padding: 10px;
          width: 90%;
        }
      }

      button {
        border-radius: 10px;
        border-color: rgb(198, 198, 198);
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        margin: 20px;
        margin-top: 40px;
        padding: 5px; 
        width: 150px;
        align-self: center;
        background-color: $secondary-color;
        color: white;
      }

      button:hover {
        background-color: rgb(0, 133, 0);
      }
    }
  }
}

.recentQuestionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;

  .questions {
    width: 90%;
    flex: 1;
    margin-bottom: 30px;

    .questionAnswer {
      line-height: 1.5em;
      border: 1px solid rgb(198, 198, 198);
      border-radius: 10px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 10px;
    }
  }
}