$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.container {
  margin-top: 35px;

  .employeeContainers {
    display: grid;
    grid-template-columns: 10% 35% 10% 35% 10%;
    grid-template-rows: 1fr;

    @media (max-width: 733px) {
      display: flex;
      flex-direction: column;
    }

    .cardTitle {
      text-align: center;
    }

    .unvaccinatedCard {
      margin-bottom: 10px;
      .cardHeader {
        display: grid;
        grid-template-columns: 50% 50%;

        h5 {
          margin-bottom: 5px;
        }
      }

      .employee {
        display: grid;
        grid-template-columns: 50% 50%;
        font-size: 13px;
        margin-top: 0px;

        p {
          margin: 0;
          padding: 3px;
        
        }

        &:nth-child(even) {
          background-color: $main-color;
        }
      }
      
    }

    .unvaccinatedFlu {
      grid-column: 2 / 3;
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-color: rgb(242, 242, 242);
      border-style: solid;
      border-radius: 20px;
      overflow: auto;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      min-height: 400px;
      max-height: 800px;

      h3 {
        margin-bottom: 0;
      }
    }

    .unvaccinatedCovid {
      grid-column: 4 / 5;
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-color: rgb(242, 242, 242);
      border-style: solid;
      border-radius: 20px;
      overflow: auto;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      min-height: 400px;
      max-height: 800px;

      h3 {
        margin-bottom: 0;
      }
    }
  }
}