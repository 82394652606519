$main-color: #e8f6f5;
$secondary-color: #56626F;
$tertiary-color: #566F63;

.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: sticky;
  z-index: 99;
  height: 10vh;
  min-height: 85px;
  // border-bottom: 3px solid black;
  // border-radius: 10px;
  // background-color: aliceblue;
  

  .logo {
    width: 150px;
    padding: 10px;
    padding-top: 20px;
    opacity: 50;
  }
  
  
  .authButton {
    z-index: 99;
  }

  nav {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    gap: 10px;
    align-items: center;

  
    .menuLinks {
      width: 20%;
      text-decoration: none;
      text-align: center;
      color: #000;
      font-size: 1rem;
      display: block;
      padding: 10px;
      color: $secondary-color;
    }
  
    a:hover {
      color: #7cc49f;
    }
  }



  @media (max-width: 600px) {
    nav {
      display: none;
    }

  }

  .hamburger {
    position: relative;
    display: block;
    width: 35px;
    cursor: pointer;
    appearance: none;
    background: none;
    outline: none;
    border: none;
    z-index: 99;
    
    @media (min-width: 600px) {
      display: none;
    }
  }

  .hamburger .bar, .hamburger:after, .hamburger:before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background-color: black;
    margin: 6px 0px;
    transition: 0.4s;
  }

}

.mobileNav {
  position: fixed;
  display: block;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100vh;
  background-color: aliceblue;
  padding-top: 120px;
  transition: 0.4s;
  z-index: 98;

  a {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto 16px;
    text-align: center;
    padding: 12px 16px;
    background-color: rgb(214, 233, 250);
    text-decoration: none;
  }
  
  a:hover {
    background-color: #7cc49f;
  }
}

.mobileNavActive {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 120px;
  background-color: aliceblue;
  transition: 0.4s;
  z-index: 98;

  a {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto 16px;
    text-align: center;
    padding: 12px 16px;
    background-color: rgb(214, 233, 250);
    text-decoration: none;
    border-radius: 10px;
  }
  
  a:visited {
    text-decoration: none;
    color: black;
  }
  a:hover {
    background-color: #7cc49f;
  }
}

