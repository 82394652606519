$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.tbScreenForm {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-gap: 30px;
  margin-left: 10px;

  .textInput {
    border-radius: 5px;
    border: 1px solid rgb(211, 211, 211);
    padding: 8px 4px 8px 4px;
    min-width: 210px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
  }

  .datePicker {
    z-index: 0;
    min-width: 210px;
  }

  @media (max-width: 733px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    margin-left: 0px;
  }

  .formTitle {
    font-size: 22px;
    font-weight: bold;
    color: $secondary-color;
    text-decoration: underline;
    margin: 10px 0 0 0;
  }

  label {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 15px;
    padding-right: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    color: $secondary-color;
    font-weight: bold;

    .subText {
      color: gray;
      font-size: 12px;
      margin-left: 5px;
      font-weight: normal;
    }
  }
  
  .tbScreenQuestions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: rgb(211, 211, 211);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    padding-top: 0;
    min-width: 240px;
  }

  .firstTbTest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: rgb(211, 211, 211);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    padding-top: 0;
    min-width: 240px;
  }

  .submitButton{
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    justify-self: center;
  }

  .defaultButton {
    border-radius: 10px;
    border-color: rgb(198, 198, 198);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 5px; 
    width: 150px;
    background-color: $secondary-color;
    color: white;
  }

  .defaultButton:hover {
    background-color: rgb(0, 133, 0);
  }
}