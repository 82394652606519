$main-color: #e8f6f5;

body {
  margin: 0;
  padding: 0;
}

.backgroundImage {
  position: absolute;
  opacity: 0.4;
}
