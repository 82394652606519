.updatePasswordContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(70px);

  .passwordForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .passwordBox {
      height: 20px;
      width: 200px;
      padding: 10px;
      margin: 5px 0;
      border-radius: 5px;
      border: 1px solid #ced4da;
    }

    .passwordBox::placeholder {
      font-size: small;
    }

      button {
        width: 100px;
        padding: 10px;
        margin: 5px 0;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 5px;
        border: 1px solid #ced4da;
        background-color: #007bff;
        color: #fff;
    }
  }
}