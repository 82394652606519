$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.adminAccountsContainer {
  .userInfo {
    margin: 10px;
    max-width: 500px;
    border-color: rgb(211, 211, 211);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 10px;

    b {
      text-decoration: underline;
      color: $secondary-color;
    }

    p {
      color: $tertiary-color;
    }

    .accountTypeDropdown {
      width: 8rem;
      font-size: 14px;
    
      .dropdownBox {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .dropdown {
    margin: 10px;
    width: 12rem;
    font-size: 14px;
  
    .dropdownBox {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    }
  }
}


.resetButton {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.resetButton:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.resetButton:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.resetButton:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.resetButton:focus {
  outline: 1px transparent;
}

.resetButton:before {
  display: none;
}

.resetButton:-webkit-details-marker {
  display: none;
}