$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.container {
  margin-top: 35px;

  .modalOuterContainer {
    display: flex;
    justify-content: center;
    overflow: visible;

    .modalContainer {
      position: absolute;
      display: flex;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      width: 100%;
      height: auto;
      min-height: 65%;

      @media (max-width: 733px) {
        height: 100vh;
      }
  
      .modalInner {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin: 10px;
        height: 100%;
        width: 60%;
        min-width: 300px;

        .closeButton {
          position: relative;
          float: right;
          top: 5px;
          right: 10px;
          font-size: 20px;
          cursor: pointer;
        }
        
        .modalContent {
          display: flex;
          flex-direction: column;
          padding: 20px;
          padding-top: 0;
          overflow: auto;
          height: 100%;
        }
      }
    }
  }

  .seasonContainer {
    display: grid;
    grid-template-columns: 10% 35% 10% 35% 10%;
    grid-template-rows: 40% 60%;
    grid-row-gap: 20px;

    @media (max-width: 733px) {
      display: flex;
      flex-direction: column;
    }

    .cardTitle {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: $secondary-color;
    }

    .dateCard {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 140px;

      .seasonDate {
        font-size: 22px;
        font-weight: bold;
        color: $tertiary-color;

        h5 {
          margin-bottom: 5px;
        }
      }

      .employee {
        display: grid;
        grid-template-columns: 50% 50%;
        font-size: 13px;
        margin-top: 0px;

        p {
          margin: 0;
          padding: 3px;
        
        }
      }
    }

    .addCovidSeasonButton {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      display: flex;
      flex-direction: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-color: rgb(242, 242, 242);
      border-style: solid;
      border-radius: 20px;
      overflow: auto;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: $main-color;
      cursor: pointer;

      .buttonText {
        font-size: large;
        font-weight: bold;
      }
    }

    .addFluSeasonButton {
      grid-column: 4 / 5;
      grid-row: 1 / 2;
      display: flex;
      flex-direction: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-color: rgb(242, 242, 242);
      border-style: solid;
      border-radius: 20px;
      overflow: auto;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: $main-color;
      cursor: pointer;

      .buttonText {
        font-size: large;
        font-weight: bold;
      }
    }

    .fluSeason {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-color: rgb(242, 242, 242);
      border-style: solid;
      border-radius: 20px;
      overflow: auto;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      min-height: 200px;
      max-height: 400px;

      h3 {
        margin-bottom: 0;
      }
    }

    .covidSeason {
      grid-column: 4 / 5;
      grid-row: 2 / 3;
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-color: rgb(242, 242, 242);
      border-style: solid;
      border-radius: 20px;
      overflow: auto;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      min-height: 200px;
      max-height: 400px;

      h3 {
        margin-bottom: 0;
      }
    }
  }
}

.formTitle {
  font-size: 20px;
  font-weight: bold;
  margin: 15px;
  color: $secondary-color;
  text-decoration: underline;
  text-align: center;
}

.formContainer {
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  
  .formTable {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    gap: 10px;
    width: 100%;
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    
    label {
      font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      padding-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
    
      .subText {
        color: gray;
        font-size: 12px;
      }
    }
  }
}

.buttonContainer {
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  .addSeasonDateButton {
    appearance: none;
    background-color: rgba(113, 202, 111, 0.763);
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    padding: 4px 10px;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
    width: 50%;
  }
  
  .addSeasonDateButton:hover {
    background-color: #F3F4F6;
    text-decoration: none;
    transition-duration: 0.1s;
  }
  
  .addSeasonDateButton:disabled {
    background-color: #FAFBFC;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959DA5;
    cursor: default;
  }
  
  .addSeasonDateButton:active {
    background-color: #EDEFF2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
  }
  
  .addSeasonDateButton:focus {
    outline: 1px transparent;
  }
  
  .addSeasonDateButton:before {
    display: none;
  }
  
  .addSeasonDateButton:-webkit-details-marker {
    display: none;
  }
}