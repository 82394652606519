$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.adminMenuContainer {
  h3 {
    color: $secondary-color;
  }

  .menuList {
    list-style-type: none;
    padding-left: 10px;
    padding-top: 0;
    margin: 0px;
    color: $tertiary-color;
    
    button {
      background: none;
      color: inherit;
      border: none;
      padding: 5px;
      padding-top: 10px;
      font: inherit;
      text-decoration: underline;
      cursor: pointer;
      outline: inherit;
    }

    li {
      @media (max-width: 733px) {
        font-size: 13px;
      }
    }
  }
}