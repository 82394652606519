.contactList {
	width: 100%;
	margin-top: 10px;
	// justify-content: center;
	// align-items: center;
}

table {
    height: 100px;
}
 
td {
    text-align: center;
		border: 1px solid black;
}