$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.mainContainer {
  background-color: $main-color;
  height: 100%;
  padding: 10px 10px 0 10px;

  .title {
    font-size: 24px;
    font-weight: bold;
    color: $secondary-color;
  }

  .infoTable {
    display: grid;
    grid-template-columns: 40% 10% 10% 10% 10% 10% 10%;
    grid-template-rows: auto;
    background-color: #44474A;
    overflow: auto;

    .headerRow {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      padding: 10px;
      border: 1px;
      border-color: black;
      border-style: solid;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
    }

    .questionRow {
      background-color: white;
      padding: 10px;
      border: 1px;
      border-color: black;
      border-style: solid;
      font-size: 13px;
    }

    .questionRow.totals {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .footerNote {
    margin: 10px;
    font-size: 12px;
  }

  .footerNote.footerNoteLink {
    margin-left: 20px;
    background-color: #5c6f56;
    color: $main-color;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 10px;
    text-decoration: none;
  }
  .link {
    color: rgb(1, 1, 187);
    text-decoration: underline;
    cursor: pointer;
  }

  .missingEmployeeModal {
    background-color: $main-color;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    margin: 10px;

    .missingEmployeeModalBody {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      gap: 10px;
      font-size: 13px;
    }

    .missingEmployeeModalHeader {
      font-size: 20px;
      font-weight: bold;
      color: $secondary-color;
      margin-bottom: 10px;
    }
  }
}