.notificationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;

  .logo {
    width: 400px;
    padding: 10px;
    padding-top: 20px;
    opacity: 50;
  }
}

.on {
  background-color: red;
}

.off {
  background-color: green;
}