$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.adminAccountsContainer {
  position: relative;
  margin-left: 10px;

  .employeeContainer {
    max-width: 500px;
    border-color: rgb(211, 211, 211);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

    .employeeInfo {
      padding: 10px;

      .terminatedBanner {
        background: red;
        padding: 5px;
        border-radius: 5px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
      }
      
      b {
        text-decoration: underline;
        color: $secondary-color;
      }
  
      p {
        color: $tertiary-color;
      }
    }

    .modalContainer {
      position: absolute;
      z-index: 1;
      width: 100%;
      // height: auto;
    }
  
    .subModalContainer {
      position: absolute;
      z-index: 1;
      height: 100%;

      .subModal {
        height: 100%;
      }
    }
  }


  .dropdown {
    margin: 10px 0px 10px 0px;
    width: 12rem;
    font-size: 14px;
  
    .dropdownBox {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    }
  }
}


.deleteEmployee {
  appearance: none;
  background-color: rgba(207, 81, 81, 0.763);
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  margin: 10px;

}

.deleteEmployee:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.deleteEmployee:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.deleteEmployee:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.deleteEmployee:focus {
  outline: 1px transparent;
}

.deleteEmployee:before {
  display: none;
}

.deleteEmployee:-webkit-details-marker {
  display: none;
}


.updateEmployee {
  appearance: none;
  background-color: rgba(113, 202, 111, 0.763);
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  margin: 10px;
}

.updateEmployee:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.updateEmployee:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.updateEmployee:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.updateEmployee:focus {
  outline: 1px transparent;
}

.updateEmployee:before {
  display: none;
}

.updateEmployee:-webkit-details-marker {
  display: none;
}