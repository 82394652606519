$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.container {
  display: flex;
  flex-direction: column;

  .containerTitle {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    color: $secondary-color;
  }
  
  .employeeDropdown {
    display: flex;
    justify-content: center;
  }

  .employeeInfo {
    padding-top: 10px;
    color: $tertiary-color;

    .employeeInfoHeader{
      margin-top: 10px;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: center;

      .employeeName {
        font-size: 22px;
        font-weight: bold;
        margin-right: 10px;
        color: $secondary-color;
        text-decoration: underline;
      }

      .employeeType {
        display: flex;

        .boldText {
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }

    .modalOuterContainer {
      display: flex;
      justify-content: center;
      overflow: visible;

      .modalContainer {
        position: absolute;
        display: flex;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
        width: 100%;
        height: auto;
        min-height: 45%;

        @media (max-width: 733px) {
          height: 100vh;
        }
    
        .modalInner {
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          margin: 10px;

          .closeButton {
            position: relative;
            float: right;
            top: 5px;
            right: 10px;
            font-size: 20px;
            cursor: pointer;
          }
          
          .modalContent {
            display: flex;
            flex-direction: column;
            padding: 20px;
            padding-top: 0;
            overflow: auto;
            height: 100%;
          }

          .tbModal {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
          }
        }
      }
    }
    .employeeContainer {
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 733px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .immunizationContainer {
        max-width: 500px;
        border-color: rgb(211, 211, 211);
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        padding: 10px;
        width: 22%;
        
        @media (max-width: 733px) {
          width: 100%;
          margin: 10px;
        }

        .primaryTitle {
          color: $secondary-color;
          font-size: 18px;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 15px;
          background-color: $main-color;
          padding: 5px;
          text-align: center;
          border-radius: 5px;
        }

        .immunizationInfo {
          min-height: 250px;
          font-size: 14px;

          .secondaryTitle {
            color: $secondary-color;
            font-size: 15px;
            text-decoration: underline;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 15px;
          }

          .header {
            font-weight: bold;
            color: $secondary-color;
          }
          .data {
            font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
          }

          .infoTable {
            display: grid;
            grid-template-columns: 60% 40%;
            row-gap: 20px;
            font-size: 14px;
            padding-top: 5px;
            padding-bottom: 15px;

            .overdueBanner {
              grid-column-start: 1;
              grid-column-end: 3;
              background-color: #f75f5f;
              color: black;
              text-align: center;
              padding: 3px;
              border-radius: 5px;
              margin-top: -15px;
            }

            &.tbTable {
              row-gap: 10px;
            }
          }

        }

        .noneFound {
          height: 250px;
        }
        
        .buttonContainer {
          display: flex;
          flex-direction: column;
          align-items: center;

          .addImmunizationButton {
            appearance: none;
            background-color: rgba(113, 202, 111, 0.763);
            border: 1px solid rgba(27, 31, 35, 0.15);
            border-radius: 6px;
            box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
            box-sizing: border-box;
            color: #24292E;
            cursor: pointer;
            font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
            font-weight: 500;
            line-height: 20px;
            list-style: none;
            padding: 4px 10px;
            transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            vertical-align: middle;
            white-space: nowrap;
            word-wrap: break-word;
            width: 75%;
          }
          
          .addImmunizationButton:hover {
            background-color: #F3F4F6;
            text-decoration: none;
            transition-duration: 0.1s;
          }
          
          .addImmunizationButton:disabled {
            background-color: #FAFBFC;
            border-color: rgba(27, 31, 35, 0.15);
            color: #959DA5;
            cursor: default;
          }
          
          .addImmunizationButton:active {
            background-color: #EDEFF2;
            box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
            transition: none 0s;
          }
          
          .addImmunizationButton:focus {
            outline: 1px transparent;
          }
          
          .addImmunizationButton:before {
            display: none;
          }
          
          .addImmunizationButton:-webkit-details-marker {
            display: none;
          }
        }
      }
    }
  }
}

