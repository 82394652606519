$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.settingsContainer {
  display: flex;
  height: 100%;
  min-height: 90vh;

  .adminMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $main-color;
    border-radius: 0px 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 8px;
    max-width: 200px;
    min-width: 110px;

    @media (max-width: 733px) {
      display: none;
    }
  }

  .mobileMenuContainer {
    @media (min-width: 733px) {
      display: none;
    }

    position: fixed;
    left: 0;
    height: 100%;
    display: flex;
    z-index: 100;
    .adminMenuMobile{
      display: none;
    }
  
    .adminMenuMobileActive{
      @media (min-width: 733px) {
        display: none;
      }
      background-color: $main-color;
      border-width: 1px;
      box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
      padding: 8px;
      max-width: 200px;
      min-width: 110px;
    }
  
    .hamburgerContainer {
      @media (min-width: 733px) {
        display: none;
      }
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $main-color;
      border-radius: 0px 10px;
      box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
      padding: 8px;
  
      .arrow {
        width: 10px;
        position: relative;
        margin-left: 10px;
  
        span {
          // margin-left: 3px;
          top: 0.5rem;
          position: absolute;
          width: .8rem;
          height: .2rem;
          background-color: #44474A;
          transition: all 0.2s ease;
        }
  
        .upperArrow {
          top: 0;
          transform: rotate(45deg);
        }
        .lowerArrow {
          transform: rotate(-45deg);
        }
        .secondUpperArrow {
          top: 0;
          transform: rotate(45deg);
          transform-origin: 3px -7px;
  
        }
        .secondLowerArrow {
          transform: rotate(-45deg);
          transform-origin: 3px 10px;
        }
      }
  
      .arrowActive {
        width: 10px;
        position: relative;
        margin-left: 10px;
  
        span {
          // margin-left: 3px;
          top: 0.5rem;
          position: absolute;
          width: .8rem;
          height: .2rem;
          background-color: #44474A;
          transition: all 0.3s ease;
        }
  
        .upperArrow {
          top: 0;
          transform: rotate(135deg);
        }
        .lowerArrow {
          transform: rotate(45deg);
        }
        .secondUpperArrow {
          top: 0;
          transform: rotate(135deg);
          transform-origin: 3px 0px;
  
        }
        .secondLowerArrow {
          transform: rotate(45deg);
          transform-origin: 3px -7px;
        }
      }
    }
  }

  @media (max-width: 733px) {
    .adminMenu, .adminAccounts, .createAccount, .manageEmployees, .createEmployee {
      margin-left: 35px;
      flex-grow: 1;
    }
  }

  .adminAccounts {
    // border-radius: 10px 0px;
    padding: 10px;
    flex-grow: 1;
  }

  .createAccount {
    // padding-left: 40px;
    // padding-top: 40px;
    padding: 10px;
    flex-grow: 1;
  }

  .manageEmployees {
    // border-radius: 10px 0px;
    padding: 10px;
    flex-grow: 1;
  }

  .createEmployee {
    // padding-left: 40px;
    padding: 10px;
    flex-grow: 1;
  }
}