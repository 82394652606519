.loginFormContainer {
  display: flex;

  .loginForm {
    width: 100%;
    padding: 15px;
    margin: auto;
    display: inherit;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      margin: 0;
    }

    .loginTextBox {
      width: 100%;
      padding: 10px;
      margin: 5px 0;
      font-size: 16px;
      line-height: 1.5;
      border-radius: 5px;
      border: 1px solid #ced4da;
    }

    button {
      width: 100px;
      padding: 10px;
      margin: 5px 0;
      font-size: 16px;
      line-height: 1.5;
      border-radius: 5px;
      border: 1px solid #ced4da;
      background-color: #007bff;
      color: #fff;
      transform: translateX(10px);
    }
  }
}