$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.createUpdateForm {
  display: flex;
  flex-direction: column;

  .employeeInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
    max-width: 500px;
    border-color: rgb(211, 211, 211);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    padding-top: 0;

    @media (max-width: 733px) {
      margin: 0px;
    }
  }

  .employeeInfoUpdate {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  label {
    margin-bottom: 8px;
    color: $secondary-color;
  }

  input {
    margin-bottom: 16px;
    border-radius: 5px;
    border: 1px solid rgb(211, 211, 211);
    padding: 8px 4px 8px 4px;
    min-width: 200px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
  }

  b {
    color: $secondary-color;
  }

  p {
    color: $tertiary-color;
  }

  button {
    border-radius: 10px;
    border-color: rgb(198, 198, 198);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 5px; 
    width: 150px;
    background-color: $secondary-color;
    color: white;
  }

  button:hover {
    background-color: rgb(0, 133, 0);
  }

}

.immunizationForms {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-gap: 30px;
  margin-left: 10px;

  @media (max-width: 733px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    margin-left: 0px;
  }

  h2 {
    color: $secondary-color;
    // margin-bottom: 20px;
  }

  .covidImmunizationForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: rgb(211, 211, 211);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    padding-top: 0;
    // min-width: 240px;
    p {
      color: $secondary-color;
    }
  }

  .fluImmunizationForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: rgb(211, 211, 211);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    padding-top: 0;
    // min-width: 240px;
    p {
      color: $secondary-color;
    }
  }

  .immunizationSubmitButton {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    justify-content: center;
    align-items: top;
    margin-top: 20px;
    height: 30px;
  }

  .defaultButton {
    border-radius: 10px;
    border-color: rgb(198, 198, 198);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 5px; 
    width: 150px;
    background-color: $secondary-color;
    color: white;
  }

  .defaultButton:hover {
    background-color: rgb(0, 133, 0);
  }

  .submitButton {
    margin-top: 20px;
    margin-left: 10px;
  }
}

.datePicker {
  z-index: 0;
  min-width: 210px;
}