$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.section {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;

  @media (max-width: 752px) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 20px;
  }

  .messageBox {
    position: relative;
    display: flex;
    flex-direction: column;
    border-color: rgb(242, 242, 242);
    border-style: solid;
    border-radius: 20px;
    overflow: auto;
    margin-top: 40px;
    width: 40%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: $main-color;

    .loadingAnimation {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      z-index: 10;
    }

    .modalContainer {
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      
      .modal {
        text-align: center;
        background-color: white;
        height: 50%;
        width: 90%;
        border-radius: 10px;
        position: relative;

        .nowOrLaterForm {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 10px;

          .radioButtons {
            margin-bottom: 10px;
            width: 100%;
            justify-content: center;
            display: flex;
    
            .radioButton {
              margin: 0 10px;
            }
          }

          .scheduledForm {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            font-size: 14px;

            .dateTime {
              margin-bottom: 10px;
              width: 100%;
              justify-content: left;
              display: flex;
              align-items: center;

              
              .dateTimePicker {
                margin-left: 10px;
                // width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
              }
            }
          }

          button {
            border-radius: 10px;
            border-color: rgb(198, 198, 198);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
            // margin: 20px;
            // margin-top: 40px;
            padding: 5px; 
            width: 150px;
            align-self: center;
            background-color: $secondary-color;
            color: white;
          }
        
          button:hover {
            background-color: rgb(0, 133, 0);
          }
        }
      }
      
      
      .closeButton {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    @media (max-width: 752px) {
      width: 90%;
    }

    h3 {
      text-align: center;
      color: $secondary-color;
      font-size: 25px;
      margin-bottom: 1rem;
    }

    h4 {
      text-align: center;
      color: $secondary-color;
      margin-bottom: 1rem;
    }

    p {
      text-align: center;
      color:$tertiary-color;
      margin: 5px;
    }
  
    .messageBoxContent {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: flex-start;
      height: auto;

      .radioButtons {
        margin-bottom: 20px;
        width: 100%;
        justify-content: center;
        display: flex;

        .radioButton {
          margin: 0 10px;
        }
      }

      .dropdown {
        margin-bottom: 20px;
        width: 75%;

        .dropdownBox {
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        }
      }

      .textMessageBox {
        width: 90%;
        justify-content: center;
        display: flex;

        .textMessage {
          text-align: left;
          border-color: rgb(198, 198, 198);
          border-radius: 7px;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
          resize: none;
          padding: 10px;
        }
      }

      button {
        border-radius: 10px;
        border-color: rgb(198, 198, 198);
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        margin: 20px;
        margin-top: 40px;
        padding: 5px; 
        width: 150px;
        align-self: center;
        background-color: $secondary-color;
        color: white;
      }
    
      button:hover {
        background-color: rgb(0, 133, 0);
      }
    }
  }

  .infoBox {
    display: flex;
    flex-direction: column;
    border-color: rgb(242, 242, 242);
    border-style: solid;
    border-radius: 20px;
    overflow: auto;
    margin-top: 40px;
    min-width: 300px;
    width: 30%;
    // height: 450px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: $main-color;

    @media (max-width: 733px) {
      width: 90%;
      margin-top: 10px;
      height: 100%;
      padding-bottom: 20px;
      padding-top: 0;
    }

    h3 {
      text-align: center;
      color: $secondary-color;
      font-size: 25px;
      margin-bottom: 1rem;
    }

    p {
      text-align: center;
      color:$tertiary-color;
      margin: 5px;
    }
  }
}

.recentsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;

  .recents {
    width: 90%;
    flex: 1;
    margin-bottom: 30px;

    .questionAnswer {
      line-height: 1.5em;
      border: 1px solid rgb(198, 198, 198);
      border-radius: 10px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 10px;

      .scheduledSpan {
        text-align: center;
        background-color: $tertiary-color;
        border-radius: 15px;
        color: #ffffff;
        margin-left: 10px;
        padding: 4px 12px;
      }
    }
  }
}