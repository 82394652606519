$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.nursingContainer {

  h2 {
  text-align: center;
  width: 100%;
  }

  .nursingSelectionContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 10px;
    margin-bottom: 15px;
    margin-top: 15px;

    .nursingCard {
      background-color: $main-color;
      border-radius: 20px;
      width: 200px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      padding: 15px;

      .cardTitle {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }

      .cardDescription {
        margin-top: 5px;
        font-size: 14px;
        text-align: center;
        text-wrap: wrap;
      }
    }

    .nursingCard:hover {
      cursor: pointer;
      background-color: $secondary-color;
      color: white;
    }
  }
}