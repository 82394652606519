.modalContainer {
  height: 100%;
  
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    max-width: 500px;
    border-radius: 5px;
    height: 100%;

  .modalContent {
    margin: 20px 300px 20px 100px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;

    margin: 10px;
    border-radius: 5px;
    padding: 10px;

    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
  
}