.bodyPage {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;

  .imageDiv {
    height: 700px;
    width: 800px;
    border-radius: 10px;
    margin-bottom: 40px;
    position: relative;
  
    img {
      position: absolute;
      border-radius: inherit;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 768px) {
    .imageDiv {
      height: 400px;
      width: 400px;
    }
  }
}

// .bodyPage::before {
//   content: "";
//   // background-image: url('public/background-image.webp');
//   background-size: cover;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   z-index: -1;
//   opacity: 0.4;
// }

.ourStory {
  width: 60%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px;
  padding-top: 0px;
  padding-bottom: 0px;

  p {
    line-height: 2.5;
  }
}
