$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

h2 {
  color: $secondary-color;
  text-decoration: underline;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  margin-top: 10px;
  max-width: 500px;
  border-color: rgb(211, 211, 211);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  padding: 10px;
  padding-top: 0;


  label {
    margin-bottom: 8px;
    color: $secondary-color;
  }

  input {
    margin-bottom: 16px;
  }

  .dropdownBox {
    font-size: 14px;
  }

  b {
    color: $secondary-color;
  }

  p {
    color: $tertiary-color;
  }

  button {
    border-radius: 10px;
    border-color: rgb(198, 198, 198);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    // margin: 20px;
    // margin-top: 40px;
    padding: 5px; 
    width: 150px;
    // align-self: center;
    background-color: $secondary-color;
    color: white;
  }

  button:hover {
    background-color: rgb(0, 133, 0);
  }
}
