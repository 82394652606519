$main-color: #e8f6f5;
$secondary-color: #5c6f56;
$tertiary-color: #44474A;

.title {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  color: $secondary-color;
  text-decoration: underline;
  text-align: center;
}

.container {
  margin: 10px;
  display: flex;
  align-items: center;
  width: 500px;
  min-width: 375px;
  
  .formTable {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    gap: 10px;
    width: 100%;
    margin: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    
    label {
      font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      padding-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
    }

    .subText {
      color: gray;
      font-size: 12px;
      margin-top: -5px;
    }

    .twoColumns {
      grid-column: 1 / 3;
      text-align: center;
    }
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  .addImmunizationButton {
    appearance: none;
    background-color: rgba(113, 202, 111, 0.763);
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    padding: 4px 10px;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
    width: 75%;
  }
  
  .addImmunizationButton:hover {
    background-color: #F3F4F6;
    text-decoration: none;
    transition-duration: 0.1s;
  }
  
  .addImmunizationButton:disabled {
    background-color: #FAFBFC;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959DA5;
    cursor: default;
  }
  
  .addImmunizationButton:active {
    background-color: #EDEFF2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
  }
  
  .addImmunizationButton:focus {
    outline: 1px transparent;
  }
  
  .addImmunizationButton:before {
    display: none;
  }
  
  .addImmunizationButton:-webkit-details-marker {
    display: none;
  }
}