.notice {
  background-color: rgba(179, 233, 166, 0.34);
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.alert {
  background-color: rgb(233 166 166 / 34%);
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.flashMessage {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.flashMessage.show {
  opacity: 1;
}

.flashMessage.hide {
  opacity: 0;
}